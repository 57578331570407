
import * as Components from "./Beachwood"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "beachwood"
}

