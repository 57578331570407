import React from 'react'
import './QRMenu.scss'
import AutoMenu from '../../react/AutoMenuTwo'
import {Link, useParams, Route, useRouteMatch} from 'react-router-dom'
export default function () {
  let {path} = useRouteMatch()
  let match = useRouteMatch(`${path}/:categoryId`)
  let categoryId = match ? match.params.categoryId : false
  
  return(
    <div className="qr-menu">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt)"}}>
        <h1>Catering</h1>
      </div> */}

      

     

      <AutoMenu 
      subMenuFilter={subMenuFilter}
      noPhoto categoryId={categoryId} disableOrder />

     
    </div>
  )
}


function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (hours < 22)
  )
  const isBreakfast = (
    (hours < 15)
  )

  const isPromo =     tag.indexOf('promo') !== -1 
  const isBreakfastMenu =     tag.indexOf('breakfast') !== -1 
  const isLunchMenu =     tag.indexOf('lunch') !== -1 
  const isSouthOfBorderMenu =     tag.indexOf('south-of') !== -1 
  const isDinnerMenu =     tag.indexOf('dinner') !== -1 
  const isQRCode =     tag.indexOf('qr-only') !== -1 
  const isOnlineOnly =     tag.indexOf('online-only') !== -1 
  const isAlwaysOn =     tag.indexOf('all') !== -1 
  
  if (isOnlineOnly) {
    return false
  }
  
  if (((day > 2) || (day === 0)) && (hours > 15)) {
    //wed - sun
    
      
      if (isAlwaysOn) {
        return true
      }
      if (isDinnerMenu) {
        return true
      }
      return false
  
  }

  if (isDinnerMenu) {
    return false
  }
  
  if (isQRCode) {
    if ((hours > 15)) {
      return true

    } else {
      return false
    }
  }
  if (isBreakfastMenu) {
    console.log('breakfast', hours)
    if ((hours < 16) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // no monday
      return true
    } else if ((hours >= 21) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // breakfasat preorder
      return true
    } else {
      return false
    }
  }
  if (isLunchMenu) {
    if ((hours>= 11) && (hours < 21)) {
      return true
    } else {
      return false
    }
  }
  if (isSouthOfBorderMenu) {
    if ([1,2,3,4,5].indexOf(day) !== -1) {
      
      if ((hours>= 11) && (hours < 21)) {
        return true
      } else {
        return false
      }
    } else {
      if ((hours>= 16) && (hours < 21)) {
        return true
      } else {
        return false
      }
    }
  }
 
  if (isPromo) {return false}

  return true
}
