import React from 'react'
// import './merchandise.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="merch-page">
      <div className="page-header">
        <h1 className='page-title'>Merchandise</h1>

        <p>**For in person pickup and 5-mile delivery, order below. For nation-wide shipping, <a href="https://merch.beachwoodcafe.com">place your order here.</a></p>
      </div>
      

      <AutoMenu altStyleA tagsToShow={['merch']} />


      <div className="page-header">
        <p>
          if you encounter technical issues, you may contact <a href="mailto:beachwood@hidethellama.com">beachwood@hidethellama.com</a>
        </p>
      </div>

      
      {/* <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div> */}
    </div>
  )
}
