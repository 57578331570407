import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
import QRMenu from './QRMenu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import Merch from './Merch'
import Form from './Form'
import './index.scss'
import './altStyleA.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import urls from './urls'
import AutoMenu from '../../react/AutoMenu'
import AutoMenuCustom from './AutoMenu'



import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}

const urlMappings = urls.filter(({content, redirect}) => {
  return !!content || !!redirect
}).map(({origin, content, redirect}) => {
  const pageObj = { path: origin, unlisted: true }
  if (content) {
    pageObj.component = content
  }
  if (redirect) {
    console.log(redirect)
    pageObj.component = () => (<Redirect to={redirect}></Redirect>)
  }
  return pageObj
})


export const customOrderAppPaths = ["/menu", "/menus"]

export const pages = [
  {external: true, path: "https://beachwoodcafe.com", label: "Home" },
  {path: "/order", label: "Order" },
  {path: "/signup", label: "Newsletter", component: Form },
  {path: "/merch", label: "Merch", component: Merch },
  {path: "/house", hidden: true, notExact: true, component: QRMenu },
  // {path: "/house/:categoryId", hidden: true, component: QRMenu },
  {path: "/all", hidden: true, component: AllItems},
  {path: "/all/:id", label: "Menu", component: AllItems, hidden: true },
  // {path: "/catering", label: "Catering", component: Catering },
  // {path: "/about/the-cafe", label: "About", component: About },
  // {path: "/find-us", label: "Find Us", component: Contact },
  // {path: "/catering", label: Caterings", component: Catering },
].concat(urlMappings)

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav aria-label="secondary" className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

export default function AllItems ({match}) {
  const id = match.params.id
  return(
    <div className="landing-page-wrapper">
      <div className="">
        <div
            className="page-header">
          <div 
          className="text">
              <h1 style={{
            textAlign: 'center',
            fontWeigth: 800,
            fontSize: "1.5em"
          }}>All Menu Items</h1>
      
      
          </div>
      
          </div>
      {/*
          <p
          style={{
            textAlign: 'center',
            fontWeight: 700
          }}
          ><a href="/public/mikaza/mikaza-v2.pdf">PDF Menu</a></p>         */}
        {id ?
        <AutoMenuCustom
        categorysToShow={[id]}
        disableOrder={true}
      ></AutoMenuCustom>
        :
        <AutoMenuCustom
          disableOrder={true}
        ></AutoMenuCustom>
      }
      
      
      </div>
    </div>
  )
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



export function Header () {
    const now = new Date()
    const day = now.getDay() // Sunday - Saturday : 0 - 6
    const hours = now.getHours() //0 to 23
    const isMorning = (hours < 16)

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">

        <div
          className="hero-banner">
          <div className="text">
            {/* <div className="hero">
              <img src={heroUrl} alt=""/>
            </div> */}
            <h1>Yes We Are Open!</h1>


            <div className="info">
              <p>Mon 9am - 4pm</p>
              <p>🔥 Tue-Sun 9am - 9pm</p>
              {/* {(isMorning) && <p

                >Pre-Order Available</p>} */}
                <p className="small">⛱outdoor seating till 8pm</p>
              <p>Pickup<br/> Curbside<br/> 🚘 Delivery</p>
              <p><Link to="/order" className="order-online-button">Order Pickup / Delivery</Link></p>
              {/* <p>10% Rewards</p> */}
              {/* <p>ALL WEEK</p> */}

            </div>
            <div className="tag-line">

            </div>


          </div>

        </div>

        <Form></Form>

        <AutoMenu
          width={600}
          ></AutoMenu>


        {/* <div className="location">
          <img src="https://afag.imgix.net/beachwood-cafe/exterior.jpg?w=1000" alt=""/>
        </div> */}


        {/* <div className="menu">

          <div className="food-gallery">
            <div className="photo">
              <Link to="/menu">View Menu</Link>
            </div>
            {foodImages.map(({url}) => {
              return(
                <div className="photo">
                  <img src={`${url}?w=300&h=300&fit=crop`} alt=""/>
                </div>
              )
            })}
          </div>
        </div> */}

        {/* <div className="events">

        </div> */}

{/*
        <div className="features">

          {sections.map(({h1, sub, img, content, link, linkText, className=""}) => {
            return(
              <section className={"feature-section" + className}>
                <div className="photo">
                  <img src={img} alt=""/>
                </div>
                <div className="content">
                  <h3>{h1}</h3>
                  <div className="text">
                    <p>{content}</p>
                  </div>
                  <div className="more-content">
                    <Link className="link" to={link}>{linkText}</Link>
                  </div>
                </div>
              </section>
            )
          })}
        </div> */}







      </div>
    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-page">
        <div className="ordering-header">
          
          {/* <div className="text">
            <h1 className="title">
              Beachwood Ordering <br/>
              Pickup or Delivery
            </h1>
            <div className="hours">
            <div className="hour">
              Monday 9am - 4pm
            </div>
            <div className="hour">
              Tue-Sun 9am - 9pm
            </div>
            </div>
          </div> */}
        
        </div>
        <AutoMenu altStyleA subMenuFilter={subMenuFilter}></AutoMenu>
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (hours < 22)
  )
  const isBreakfast = (
    (hours < 15)
  )

  const isPromo =     tag.indexOf('promo') !== -1 
  const isBreakfastMenu =     tag.indexOf('breakfast') !== -1 
  const isLunchMenu =     tag.indexOf('lunch') !== -1 
  const isSouthOfBorderMenu =     tag.indexOf('south-of') !== -1 
  const isDinnerMenu =     tag.indexOf('dinner') !== -1 
  const isQRCode =     tag.indexOf('qr-only') !== -1 
  const isAlwaysOn =     tag.indexOf('all') !== -1 
  
  if (((day > 2) || (day === 0)) && (hours > 15)) {
    //wed - sun
      if (isQRCode) {
        return false
      }
      
      if (isAlwaysOn) {
        return true
      }
      if (isDinnerMenu) {
        return true
      }
      return false
  
  }

  if (isDinnerMenu) {
    return false
  }

  if (isQRCode) {
    return false
  }

  if (isBreakfastMenu) {
    console.log('breakfast', hours)
    if ((hours < 16) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // no monday
      return true
    } else if ((hours >= 21) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // breakfasat preorder
      return true
    } else {
      return false
    }
  }
  if (isLunchMenu) {
    if ((hours>= 11) && (hours < 21)) {
      return true
    } else {
      return false
    }
  }
  if (isSouthOfBorderMenu) {
    if ([1,2,3,4,5].indexOf(day) !== -1) {
      
      if ((hours>= 10) && (hours < 21)) {
        return true
      } else {
        return false
      }
    } else {
      if ((hours>= 16) && (hours < 21)) {
        return true
      } else {
        return false
      }
    }
  }
 
  if (isPromo) {return false}

  return true
}
